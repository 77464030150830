  <template>
    <div class="summary">
      <div class="columns">
          <div class="column">
               <div class="columns statues-col browse-subject-col" v-for="statues in browseStatues" :key="statues.idStatute">
                <div class="column">

                   {{statues.actName}} <br>
                  <span class="grey-color"> {{statues.jCount}} judgement(s) </span>

                </div>
                <div class="column has-text-right pt-5"  >
                   <router-link to="#" class=" browse-icon"> <i class="fa fa-chevron-right" aria-hidden="true"></i> </router-link>
                </div>

               </div>
          </div>

      </div>

    </div>
</template>
<script>
// import "../../assets/style/custom.css"
export default {
  name: 'browseStatues',
  props:{
    browseStatues: []
  }

}
</script>
