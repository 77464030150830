<template>
  <div id="LatestJudgementDetails" class="m-6 statues" >

           <section class="">
       <h3 class="mb-5 heading2">  <strong> Subjects : {{browseByStatues.length}} </strong> </h3>
         <BrowseByStatues :browseStatues="browseByStatues" />
   </section>
  </div>
</template>
<script>
import BrowseByStatues from "@/components/BrowseByStatues.vue";
const axios = require('axios');
import "../../assets/style/custom.css"
export default {
    name: 'Statues',
    components:{ BrowseByStatues },
    props: {
      slugid: {
        default: null,
      },
  },
  data() {
      return {
          browseByStatues:[],
      }
    },
    mounted (){
          this.getBrowseStatues();

    },
    methods:{
        getBrowseStatues(){
        const loadingComponent = this.$buefy.loading.open({
                    container: this.isFullPage
        })
          axios.get('https://staging.spotlawapp.com/newapi/browseByStatutes.php').then( res=> {
                this.browseByStatues = res.data.data;
                loadingComponent.close();
          })

        }

    }

}
</script>

